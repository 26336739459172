import React from 'react';

export default () => (
  <svg
    className="logoDisMoi"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 244.4"
  >
    <path
      fill="#29B3A9"
      d="M747.9,52.1c-13.7,0-26,7.1-32.6,17.6c-8.2-10.9-19.7-17.6-34.4-17.6c-34.2,0-50.4,20.6-50.4,51.5v74
	c0,13,8.8,20.6,20.4,20.6h0.6c3.8,0,6.9-3.1,6.9-6.9v-89.9c0-15.5,8.2-24.2,19.1-24.2c12.4,0,21,8,21,26.7v73.8
	c0,13.4,8.2,20.6,20.6,20.6h0.6c3.8,0,6.9-3.1,6.9-6.9v-90.6c0-15.5,7.8-23.5,19.1-23.5c12.4,0,20.8,8,20.8,26.7v73.8
	c0,13.4,6.1,20.6,21.4,20.6c3.8,0,6.9-3.1,6.9-6.9V100C794.9,67.9,773.9,52.1,747.9,52.1z M873.1,82.2c-36.5,0-56.1,27.3-56.1,61.3
	c0,30,19.5,57.1,53.6,57.1c36.5,0,56.1-27.1,56.1-61.1C926.6,109.5,907.1,82.2,873.1,82.2z M873.1,175.5
	c-18.7,0-26.9-13.4-26.9-35.9c0-18.7,8.2-32.1,24.4-32.1c18.5,0,26.9,13.4,26.9,36.1C897.4,162,889.5,175.5,873.1,175.5z
	 M963.6,76.8c9,0,16.6-7.6,16.6-16.6s-7.6-16.4-16.6-16.4c-9,0-16.4,7.4-16.4,16.4S954.6,76.8,963.6,76.8z M995.1,176.1
	c-12.4,0-17.2-3.6-17.2-14.9v-52.9c0-14.3-7.6-21-20.2-21c-4.4,0-8,3.6-8,8v68.7c0,22.9,12,35.3,33.8,35.3c12,0,16.8-4.8,16.8-17
	v-0.8C1000.4,178.4,998,176.1,995.1,176.1z"
    />
    <path
      fill="#2855A0"
      d="M401.4,64.8c-11.8-8.7-28.7-13.3-47.6-13.3H326h-4.2c-7.8,0-12.9,2.2-16.9,7.6c-2.9,4-3.6,6.7-3.6,17.1v98.5
	c-0.9,17.6,6.7,25.3,24.5,24.7l33.1,0.4c41.6,0.7,69.8-28.9,69.8-73.4C428.7,100.2,418.7,77.5,401.4,64.8z M352,175.5h-18.9V90.2
	c0-8,6.5-14.4,14.4-14.4c16.2,0,26.1,3,34.3,10.2c10.2,8.9,15.2,22.1,15.2,39.5C396.9,157.8,381,175.5,352,175.5z M567.3,130.3
	c-19.3-3.2-22.7-5.5-22.7-11.1c0-7.4,5.5-12.6,22.7-12.6c7.5,0,17,3.3,23.9,6.1c4.5,1.9,9.5-1.5,9.5-6.3c0-10.9-10.5-24.2-36.1-24.2
	c-29,0-48.1,18.1-48.1,39.3c0,17.2,11.6,26.7,35.5,30c19.5,2.7,23.5,5.9,23.5,12.4c0,8.4-6.7,12.4-20.6,12.4c-7.1,0-16.4-3-23.6-5.8
	c-5.5-2.1-11.4,2-11.4,7.9c0,11.6,15.5,22.3,36.3,22.3c30.5,0,47.3-14.3,47.3-38.2C603.5,144.4,590.9,134.1,567.3,130.3z
	 M497.2,176.1c-12.4,0-17.2-3.6-17.2-14.9v-52.9c0-14.3-7.6-21-20.2-21c-4.4,0-8,3.6-8,8v68.7c0,22.9,12,35.3,33.8,35.3
	c12,0,16.8-4.8,16.8-17v-0.8C502.5,178.4,500.1,176.1,497.2,176.1z M465.7,76.8c9,0,16.6-7.6,16.6-16.6s-7.6-16.4-16.6-16.4
	c-9,0-16.4,7.4-16.4,16.4S456.7,76.8,465.7,76.8z"
    />
    <path
      fill="#2855A0"
      d="M164.9,80.5c-8.3-7.3-18.4-10.4-34.9-10.4c-8.1,0-14.7,6.6-14.7,14.7v86.7h19.2c29.6,0,45.7-17.9,45.7-50.8
	C180.3,103,175.2,89.6,164.9,80.5z M133,0C62.8,0,5.9,54.7,5.9,122.2c0,16.6,3.4,32.4,9.6,46.8c3.1,7.3,2.3,15.6-1.9,22.3L2.8,208.1
	c-6.4,9.9,0.7,23,12.5,23l9.2,0c25.5,0,51,3,75.7,9.3c10.5,2.7,21.4,4.1,32.8,4.1c70.2,0,127.1-54.7,127.1-122.2
	C260.1,54.7,203.2,0,133,0z M187.9,192.3c-12.6,8.5-28.2,13.1-45.9,12.8l-10-0.1l-56.7-0.1c-1.8,0.2-3.7,0.2-5.7,0.2
	c-1.8,0-3.4-0.1-4.9-0.3l-1.1,0c-2.2,0-4.1-0.6-5.6-1.7c-6.6-2.8-9.5-9-9.5-19.5v-1.1c0-3.7,3-6.6,6.6-6.6
	c14.1,0,20.4-3.7,21.5-14.9V75.4l0-10.5c0-4.3,1-8.3,2.6-11.9c4.3-9.7,14.1-16.5,25.4-16.5h31.8c21.5,0,40.7,5.3,54,15.2
	c19.7,14.4,31.1,40.2,31.1,70C221.5,153.3,208.9,178.3,187.9,192.3z"
    />
  </svg>
);
